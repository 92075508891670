var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('content-card',{attrs:{"title":("홈 " + (this.getTitleType()) + " 기획전 관리"),"notification":"설명을 누르면 기획전 목록으로 이동합니다."}},[(_vm.loading > 0)?_c('spinner'):_c('div',[_c('el-table',{ref:"table",staticClass:"table table-responsive table-flush table-striped align-items-center mb-0",staticStyle:{"text-align":"center"},attrs:{"header-row-class-name":"thead-light","data":_vm.collectionSlots}},[_c('el-table-column',{attrs:{"label":"아이디","prop":"id"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.id)+" ")]}}])}),_c('el-table-column',{attrs:{"label":"설명","prop":"description"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b-link',{attrs:{"to":_vm.collectionListNameRoute(row.id)}},[_vm._v(" "+_vm._s(row.description))])]}}])}),_c('el-table-column',{attrs:{"label":"노출 최대 개수","prop":"maximumCountOfCollection"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.maximumCountOfCollection)+" ")]}}])}),_c('el-table-column',{attrs:{"label":"수정","prop":"id"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('frip-button',{staticClass:"my-1",attrs:{"outline":"","to":("./slots/" + (row.id)),"type":'secondary'}},[_vm._v("수정")])]}}])})],1),_c('b-alert',{attrs:{"show":_vm.showErrorMessageTime,"dismissible":"","variant":"frip-warning"},on:{"dismissed":function($event){_vm.showErrorMessageTime = 0},"dismiss-count-down":_vm.countDownChanged}},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")]),_c('b-alert',{attrs:{"show":_vm.showSuccessMessageTime,"dismissible":"","variant":"frip-success"},on:{"dismissed":function($event){_vm.showSuccessMessageTime = 0},"dismiss-count-down":_vm.countDownSuccessChanged}},[_vm._v(" "+_vm._s(_vm.successMessage)+" ")])],1),_c('b-card-footer',{staticClass:"text-right"},[_c('b-row',{staticClass:"float-right mx-3"},[_c('router-link',{attrs:{"to":"./create"}},[_c('frip-button',{attrs:{"type":"primary","loading":_vm.loading > 0}},[_vm._v("슬롯 추가")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
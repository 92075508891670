


















































































import Vue, { VueConstructor } from 'vue';
import { ApolloError } from 'apollo-client';
import Spinner from '@/components/Spinner.vue';
import { SLOTS_QUERY } from '../queries/query';
import { Table, TableColumn } from 'element-ui';
import { CollectionSlot, CollectionType } from '../model/slot';

export default (
  Vue as VueConstructor<Vue & { $refs: { [key: string]: HTMLElement } }>
).extend({
  name: 'CollectionSlotList',
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    Spinner,
  },
  data() {
    return {
      collectionSlots: [] as CollectionSlot[],
      showErrorMessageTime: 0,
      errorMessage: '',
      showSuccessMessageTime:
        parseInt(this.$route.params.showSuccessMessageTime) || 0,
      successMessage: this.$route.params.successMessage || '',
      selectedSlotId: 'abc',
      loading: 0,
    };
  },
  computed: {},
  mounted() {
    this.$apollo.queries.collectionSlots.refetch();
  },
  methods: {
    showErrorMessage(message: string): void {
      this.showErrorMessageTime = 5;
      this.errorMessage = message.replace('GraphQL error:', '');
    },
    countDownChanged(showErrorMessageTime: number) {
      this.showErrorMessageTime = showErrorMessageTime;
    },
    showSuccessMessage(message: string): void {
      this.showSuccessMessageTime = 5;
      this.successMessage = message;
    },
    countDownSuccessChanged(showSuccessMessageTime: number) {
      this.showSuccessMessageTime = showSuccessMessageTime;
    },
    getCollectionType(): CollectionType {
      return this.$attrs.CollectionType as CollectionType;
    },
    getTitleType(): string {
      const collectionType = this.$attrs.CollectionType as CollectionType;
      return collectionType === CollectionType.PRODUCT ? '상품' : '테마';
    },
    setSelectedSlotId(id: string): void {
      this.selectedSlotId = id;
    },
    collectionListNameRoute(id: string): {
      name: string;
      params: { slotId: string };
    } {
      return {
        name:
          this.$attrs.CollectionType === CollectionType.PRODUCT
            ? 'CollectionProductList'
            : 'CollectionThemeList',
        params: { slotId: id },
      };
    },
  },
  apollo: {
    collectionSlots: {
      query: SLOTS_QUERY,
      variables(): { type: CollectionType } {
        return { type: this.getCollectionType() };
      },
      error(error: ApolloError): void {
        console.error(error);
        this.showErrorMessage(error.message);
      },
      update(data: {
        collection: { slots: CollectionSlot[] };
      }): CollectionSlot[] {
        const result = data.collection.slots;
        return result;
      },
    },
  },
});
